import './HomePage.module.scss'

const HomePage = () => {



  return (
    <div className="body-container body-container_main">
    <div className='hello'>
      <h1>Hello guys!</h1>
      <h2>Site under construction . . .</h2>
    </div>

  </div>
  )
}

export default HomePage