


const Footer = () => {
  return (
    <footer className='body-container body-container_footer footer'>
      <p className='footer_ver'>ver 0.0.1</p>
      <p className='footer_date'>2024-06-14</p>
    </footer>
  )
}

export default Footer