import React from 'react'

const CardsPage = () => {
  return (
    <div className='body-container body-container_main hello'>
    Cards
    </div>
  )
}

export default CardsPage