

const AboutPage = () => {


  return (
    <div className='body-container body-container_main hello'>
      About me
      
      </div>
  )
}

export default AboutPage